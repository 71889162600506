import { all, put, takeLatest } from 'redux-saga/effects';
import { notify } from 'redux/actions';
import { artifactService } from 'api';
import { statuses as artifactStatuses } from 'constants/artifactListConstants';

import { actions } from './artifactStatisticsReducer';

export function* getStatisticsByStatusFlow() {
  try {
    const [statisticsData, completedData, processingData, failedData] = yield all([
      artifactService.getArtifactsStats(),
      artifactService.searchArtifacts({ statuses: artifactStatuses.completed, size: 1 }),
      artifactService.searchArtifacts({ statuses: artifactStatuses.processing, size: 1 }),
      artifactService.searchArtifacts({ statuses: artifactStatuses.failed, size: 1 }),
    ]);

    yield put(actions.getStatisticsSuccess({
      maxArtifacts: statisticsData.data.max_artifacts,
      artifactsByStatus: {
        completed: completedData.data.total_items,
        processing: processingData.data.total_items,
        failed: failedData.data.total_items,
      },
    }));
  } catch (e) {
    yield put(notify({ title: 'Error when getting artifact statistics', type: 'error' }));
  }
}

export default function* rootSaga() {
  /* istanbul ignore next */
  yield all([
    takeLatest(actions.getStatistics.type, getStatisticsByStatusFlow),
  ]);
}
