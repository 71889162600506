import { request } from 'api/core';

export const searchArtifacts = ({ page, statuses, size, sorting, searchTerm, ids }) => request({
  method: 'post',
  url: '/artifact-service/artifacts/_search',
  data: {
    page,
    statuses,
    size,
    sorting,
    search_term: searchTerm,
    ids,
    access_filter: {
      users: null,
      user_groups: null,
    },
  },
});

export const createArtifact = ({ gcsObjectId, title, originalFileName }) => request({
  method: 'post',
  url: '/artifact-service/artifacts',
  data: {
    gcs_object_id: gcsObjectId,
    title,
    original_file_name: originalFileName,
  },
});

export const deleteArtifact = id => request({
  method: 'delete',
  url: `/artifact-service/artifacts/${id}`,
});

export const getArtifactGcsObjectLink = ({ artifactId }) => request({
  method: 'get',
  url: `/artifact-service/artifacts/${artifactId}/gcs-object-link`,
});

export const generateSignedLink = ({ fileType, fileName }) => request({
  method: 'post',
  url: '/artifact-service/document-upload-links/_generate',
  data: {
    file_type: fileType,
    file_name: fileName,
  },
});

export const getArtifactsStats = () => request({
  method: 'get',
  url: '/artifact-service/artifacts-stats',
});

export const getArtifact = id => request({
  method: 'get',
  url: `/artifact-service/artifacts/${id}`,
});
